import React from 'react';
import './footer.styles.scss';

const Footer = () => {
    return (
        <div className='footer'>
        <p>Andy Hahn © - 2020 </p>
        </div>
    )
}

export default Footer